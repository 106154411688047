import React, { useEffect, useState } from "react";
import {
  Flex,
  Stack,
  Spacer,
  HStack,
  VStack,
  Box,
  Text,
  Button,
} from "@chakra-ui/react";
import HomeTitle from "./HomeTitle";
import MainLink from "../MainLink";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowDown,
} from "react-icons/md";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";

const Qa = ({ q, children }) => {
  return (
    <>
      <Box
        background="#F1F1F1"
        border="1px solid rgba(0, 0, 0, 0.2)"
        borderRadius="11px"
        py="6px"
        px="15px"
        mt="15px"
      >
        <Text fontSize="22px" lineHeight={1.5}>
          {q}
        </Text>
      </Box>
      <Text
        m={{ base: "14px 8px 19px 8px", md: "18px 57px 28px 57px" }}
        fontSize="15px"
        lineHeight="30px"
        color="#313131"
      >
        {children}
      </Text>
    </>
  );
};

const QAs = ({ isOpen, open }) => {
  const router = useRouter();
  const { locale } = router;
  const isTw = locale === "zh-TW";
  const { t } = useTranslation("common");

  return isOpen ? (
    isTw ? (
      <Box>
        <Flex direction="column" maxW="721px" w="100%">
          <Qa q="安心守護服務專案">
            車損發生時，賠償金額及營業損失依UDRIVE租賃契約辦理。UDRIVE額外提供「安心守護」加值服務，讓您在發生意外事故時，能免除租賃契約中最高
            10
            萬元的賠償與營業損失費用，無需承擔額外的經濟壓力，享受更無憂的租車體驗。參考連結：
            <MainLink href="https://www.udrive.city/blog/3134">
              https://www.udrive.city/blog/3134
            </MainLink>
          </Qa>
          <Qa q="第一次使用，身份認證多久會通過？">
            您可以先預約訂單，然後在取車前上傳證件，我們會在您取車前完成您的身份認證，請不用擔心會因此卡住您的計畫！
          </Qa>
          <Qa q="哪裡可以幫特斯拉充電？">
            凡有充電樁場站皆可充電，支援 J1772 慢充、CCS2
            快充，若需特殊規格，請聯繫客服。特斯拉 Tesla
            超充站可透過車內系統快速導航，取車時，專人也會親自與您說明充電相關事宜！
            <br />
            <MainLink href="https://www.tesla.com/zh_tw/supercharger">
              請參考：全台超級充電站分佈 | Tesla 台灣
            </MainLink>
          </Qa>
          <Qa q="充電需要費用嗎？">
            於 Tesla 超充站充電費用將由 UDRIVE
            悠駕幫您吸收，讓你旅途超充充到飽！其他充電場域，需自行依現場規範支付費用。
          </Qa>
          <Qa q="UDRIVE 特斯拉租車，可以用哪些付款方式？">
            目前 UDRIVE 特斯拉租車支持信用卡、簽證金融卡線上付款
          </Qa>
          <Qa q="發票可以打統編嗎？">
            沒問題！下訂時可以設定個人發票或公司統一編號，系統會透過 Email
            將發票資訊寄送到您的信箱。
            <br />
            若公司有長期租用需求，可聯繫客服。
          </Qa>
          <Qa q="想要更改訂單時間或地點怎麼辦？">
            取車/還車 12 小時前，可聯繫客服更改對應資訊；12 小時內每次修改需酌收
            500 元人員車輛調度費用、4 小時內每次修改需酌收1000
            元人員車輛調度費用。
            <br />
            <br />
            如於 02/04 10:00 ~ 02/10 10:00 承租
            <br />
            1.於 02/03 22:00 前，可免費修改取車時間、地址。
            <br />
            2.於 02/09 22:00 前，可免費修改還車時間、地址。
            <br />
          </Qa>
          <Qa q="租金是否含保險">UDRIVE 服務租金已含乙式車體險</Qa>
        </Flex>
      </Box>
    ) : (
      <Box>
        <Flex direction="column" maxW="721px" w="100%">
          <Qa q="What do I need to book a car on Udrive?">
            To book a car on Udrive, you must create a Udrive account, be 20
            years old or older in Taiwan, and have a valid driver’s license. If
            you’re Foreigner, you need to apply for Taiwan international drivers
            License. When you’re booking your first trip, you’ll go through a
            quick approval process by entering your driver’s license and some
            other information. In most cases, you’ll get approved in two days,
            and you’ll be set for every future road trip, business trip, and
            family vacation!
          </Qa>
          <Qa q="How does it work?">
            Easy! Just hit the “Book Now” button, select your vehicle, date,
            times, and address, and book! From there you’ll receive an E-mail to
            confirm your information. We will contact you 1 hour before your
            trip starts. You just need to show up at your designated address.No
            lines, no wait, no problem!
          </Qa>
          <Qa q="How Many Miles Can I Drive Per Day?">
            Every rental comes with 100 kilometers per day included for free -
            after that it’s NT$5.0/km if you need more! Miles are counted as the
            sum total of your trip, so a 3 day trip has 300 free kilometers.
          </Qa>
          <Qa q="How Does Charging Work?">
            Charging is super easy, you can use any Tesla superchargers
            scattered around the city or along the highways, the car will show
            you where they are in the navigation and will route you through them
            on your way to your destination! We also include charging adapters
            for non-supercharging scenarios.
          </Qa>
          <Qa q="I've Never Driven A Tesla, Is There Someone There To Show Me?">
            Every Rental has a super useful and fast lesson that shows you
            everything you need to be a master before you even get going. Just
            in case you need someone - you can call or text our customer service
            department at any time for assistance throughout your trip!
          </Qa>
          <Qa q="What is the cancellation policy?">
            You can cancel and get a full refund up to 10 days before your trip
            starts. If you cancel after the free cancellation period ends, you
            will be charged a small cancellation fee.
            <br />
            The “Earnest Money” means 30% of the Rent.
            <br />
            <br />
            If the Lessee sends the order cancellation notice at least ten (10)
            days prior to the Vehicle’s Pickup Time, 100% of the Earnest Money
            paid will be refunded to Lessee.
            <br />
            If the Lessee sends the order cancellation notice nine (9) to seven
            (7) days prior to the Pickup Time, 50% of the Earnest Money paid
            will be refunded to Lessee.
            <br />
            If the Lessee sends the order cancellation notice six (6) to four
            (4) days prior to the Pickup Time, 40% of the Earnest Money paid
            will be refunded to Lessee.
            <br />
            If the Lessee sends the order cancellation notice three (3) to two
            (2) days prior to the Pickup Time, 30% of the Earnest Money paid
            will be refunded to Lessee.
            <br />
            If the Lessee sends the order cancellation notice one (1) day prior
            to the Pickup Time, 20% of the Earnest Money paid will be refunded
            to Lessee.
            <br />
            If the Lessee sends the order cancellation notice within twenty-four
            (24) hours prior to the Pickup Time, 0% of the Earnest Money paid
            will be refunded to Lessee.
          </Qa>
        </Flex>
      </Box>
    )
  ) : (
    <Button
      onClick={() => open(true)}
      border="1px solid #333"
      bgColor="#fff"
      lineHeight="1"
    >
      {t("faqOpenButton")}
    </Button>
  );
};

const HomeFAQ = () => {
  const router = useRouter();
  const { t } = useTranslation("common");
  useEffect(() => {
    if (router.asPath.includes("#qa")) {
      open(true);
    }
  }, [router.asPath]);
  const [isOpen, open] = useState(false);

  return (
    <VStack px="25px" py="50px" id="qa" mt="-30px" pt="80px" cursor="pointer">
      <HomeTitle onClick={() => open(!isOpen)} cursor="pointer">
        <HStack>
          <Text>{t("faqTitle")}</Text>
          {isOpen ? (
            <MdOutlineKeyboardArrowDown />
          ) : (
            <MdOutlineKeyboardArrowLeft />
          )}
        </HStack>
      </HomeTitle>
      <QAs isOpen={isOpen} open={open} />
    </VStack>
  );
};

export default HomeFAQ;
